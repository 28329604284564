import React from 'react';
import { Link } from 'react-router-dom';

export default function TopHeader() {
   
    return (
        <>
       <div className='mx-3'>
       <div className='row mHeight' style={{backgroundColor:'#db261d', color: 'white', height: '25px'}}>
            <div className='col-md-6 custom-md-6'>
                <p className='mText'>GSTIN.:09AMFPL8215R1ZC</p>
            </div>
            <div className='col-md-6 custom-md-6 d-flex justify-content-end'>
                <Link to="/Admin/Login" className="nav-link mText"><svg className='mx-2 mText' xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664z"/></svg>LogIn</Link>
            </div>
        </div>
       </div>
      </>
    )
}
