import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { SessionProvider } from './Admin/SessionContext';
import Header from './Component/Header'
import Mmaterial from './Component/Mmaterial'
import Gmaterial from './Component/Gmaterial'
import Stationary from './Component/Stationary'
import Sports from './Component/Sports'
import Services from './Component/Services'
import AboutUs from './Component/AboutUs'
import Gallery from './Component/Gallery'
import ContactUs from './Component/ContactUs'
import Footer from './Component/Footer'
import TopHeader from './Component/TopHeader'
import Login from './Admin/LogIn'
import AdminSidebar from './Admin/AdminSidebar'
import AdminPanel from './Admin/AdminPanel'
import ImgUploadForm from './Admin/ImgUploadForm'
import ViewBannerForm from './Admin/ViewBannerForm'
import ImgGalryUpldFrm from './Admin/ImgGalryUpldFrm'
import ViewImgGalryFrm from './Admin/ViewImgGalryFrm'
import ClientDetails from './Admin/ClientDetails'
import './App.css';
import './CSS/mobile.css';

function App() {
  return (
    <Router>
       <SessionProvider>
        <Routes>
         <Route  path="/" element={
          <>      
          <TopHeader />          
          <Header />          
          <Mmaterial />          
          <Gmaterial />          
          <Stationary />          
          <Sports />          
          <Services />          
          <AboutUs />          
          <Gallery />          
          <ContactUs />          
          <Footer />          
          </>
          } />
         <Route  path="/Admin/Login" element={
          <>              
          <Login />                
          </>
          } />
           <Route  path="/Admin/AdminPanel" element={
            <>        
            <div className='app-container'>
              <AdminSidebar />
            <div className='content'>
              <AdminPanel />      
              <ImgUploadForm />      
            </div>
            </div>
            </>
          } />
           <Route  path="/Admin/ViewBannerForm" element={
            <>        
            <div className='app-container'>
              <AdminSidebar />
            <div className='content'>
              <AdminPanel />      
              <ViewBannerForm />      
            </div>
            </div>
            </>
          } />
           <Route  path="/Admin/ImgGalryUpldFrm" element={
            <>        
            <div className='app-container'>
              <AdminSidebar />
            <div className='content'>
              <AdminPanel />      
              <ImgGalryUpldFrm />      
            </div>
            </div>
            </>
          } />
           <Route  path="/Admin/ViewImgGalryFrm" element={
            <>        
            <div className='app-container'>
              <AdminSidebar />
            <div className='content'>
              <AdminPanel />      
              <ViewImgGalryFrm />      
            </div>
            </div>
            </>
          } />
           <Route  path="/Admin/ClientDetails" element={
            <>        
            <div className='app-container'>
              <AdminSidebar />
            <div className='content'>
              <AdminPanel />      
              <ClientDetails />      
            </div>
            </div>
            </>
          } />
        </Routes>
       </SessionProvider>
    </Router>
  );
}
   //  "@material-ui/core": "^4.12.4", 
export default App;
