import React from 'react'
import AboutUsBanner from '../Images/AboutUsBanner.jpeg';

export default function AboutUs() {
  return (
    <>
     <section className="block bg-light py-4" id="AboutUs">
     <div className="text-center mb-4">
        <p className="sub-title">The Faq's</p>
        <h2 className="home-title">About Us</h2>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex align-items-center justify-content-end">
            <img
              src="https://assets.zyrosite.com/presson88qto/STAR-mP44eQoonWF39eWL.svg"
              alt="Star"
              className="img-fluid"
            />
          </div>
        </div>

        {/* Testimonial Content */}
        <div className="row my-3">
          <div className="col-md-6">
            <img
              src={AboutUsBanner}
              alt="Large Press"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
          <h2 className="text-dark text-p MtextHeading">KamadGiri Press</h2>
            <div className='d-flex flex-column justify-content-center h-100'>
            <h6 className="text-dark fw-bold">Stationery Today</h6>
            <p className="text-dark">
              "Kamadgiri Printing Press delivers impeccable stationery solutions with finesse and precision."
            </p>
            <h6 className="text-dark fw-bold">Printers Quarterly</h6>
            <p className="text-dark">
              "Unparalleled quality and dedicated service - Kamadgiri Stationery sets the bar high for printing excellence."
            </p>
            </div>
          </div>
        </div>
      </div>
    </section>
      
    </>
  )
}
