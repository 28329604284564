import React,{useState, useEffect} from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import Bname from '../Images/brandName.png'

export default function Header() {
  const [isVisible, setIsVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
      fetch(`/api/ImageSlider`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 25) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headerStyle = {
    position: 'fixed',
    width: '100%',
    top: scrolled ? '0' : '25px',
    left: '0',
    transition: '0s ease-in-out',
    zIndex: '1',
    backgroundColor: 'rgba(255, 255, 255)',
  };
  
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) { // Change this value to determine when the icon appears
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <>
  <div id='Header'>
  <div className='header mHeader' style={headerStyle}>
   <nav className="navbar navbar-expand-lg nav-menu mobileNav" id='navbar'>
     <div className="container mx-5 MobileContainer" style={{maxWidth:'1260px'}}>
      <a className="navbar-brand center-brand" href="/">
      {/* {/* <img src="images/logo-transparent.png" alt="logo" className="logo-default" /> */}
      <img src={Bname} alt="logo" className="logo-scrolled" width='100px' />
     </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#xenav" aria-controls="xenav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="xenav">
      <ul className="navbar-nav mb-2 mb-lg-0 contentAtStart">
        <li className="nav-item active">
          <Link className="nav-link" aria-current="page" to="#Header">Home</Link>
        </li>
        <li className="nav-item ">
          <Link className="nav-link" to="#AboutUs">About Us</Link>
        </li>
        <li className="nav-item dropdown">
          <p className="nav-link mb-0" role="button" data-bs-toggle="dropdown" aria-expanded="false">Features <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down" viewBox="0 0 16 16"><path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/></svg></p>
            <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="#Mmaterial">Marketing Material</Link></li>
                <li><Link className="dropdown-item" to="#Gmaterial">Gifting Material</Link></li>
                <li><Link className="dropdown-item" to="#Stationary">Stationary Material</Link></li>
                <li><Link className="dropdown-item" to="#Sport">Sport Material</Link></li>
            </ul>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="#Services">Services</Link>
        </li>
      </ul>
      <ul className="navbar-nav contentAtEnd">
      
        <li className="nav-item">
          <Link className="nav-link" to="#">Video</Link>
        </li>
       
        <li className="nav-item">
          <Link className="nav-link" to="#Gallery">Gallery</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="#ContactUs">Contact Us</Link>
        </li>
      </ul>
      <form className="d-flex mb-3" role="search">
        <input className="form-control me-2 d-md-none" type="search" placeholder="Search" aria-label="Search" />
        <button className="btn btn-outline-success d-md-none" type="submit">Search</button>
      </form>
    </div>
  </div>
    </nav>
   </div>
  <div id="carouselExampleCaptions" className="carousel slide caroSlide mt-0">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
  {images.map((image, index) => (
        <div key={index} className={`carousel-item  carousel-image ${index === 0 ? 'active' : ''}`}>
          <img src={`/api/Uploads/${image.URL}?timestamp=${new Date().getTime()}`} className="d-block w-100 responsive" width='300' height='200' alt={image.Title} />
          <div className="carousel-caption">
          </div>
        </div>
      ))}
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
    </div>
  </div>
  <div class="whatsappWidget widgetPosition-Bottom-Right">
  <Link to='https://api.whatsapp.com/send?phone=+918756777423'><img src="https://image1.jdomni.in/jdomni_email/whatsapp_popup_2011271203.png?output-format=webp" alt='a' class="whatsappIcon" /></Link>
  </div>
  {isVisible && (
  <div id="topcontrol" title="Scroll Back to Top" style={{position: 'fixed', bottom: '23px', right: '90px', opacity: '1', cursor: 'pointer', zIndex: '101'}} onClick={scrollToTop}><i class="icon-pagination-up up-icon"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
</svg></i></div>)}
    </>
  )
}
