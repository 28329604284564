import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import { useSession } from './SessionContext';

export default function AdminPanel() {
  const { userLoginType, setUserLoginType} = useSession();


  useEffect(() => {
    var loginType = document.cookie.replace(/(?:(?:^|.*;\s*)loginType\s*=\s*([^;]*).*$)|^.*$/, "$1");
  
    if (loginType) {
      setUserLoginType(loginType);
    }
  }, [setUserLoginType]);
  

  const handleLogout = () => {
    fetch('/Logout', {
      method: 'POST',
    })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.error('Logout failed:', error);
      });
  };

 return (
    <>
    <nav class="navbar bg-white shadow-sm mb-1">
      <div class="container-fluid">
      <form class="d-flex mt-0" role="search">
      <h1 className="navbar-brand brand d-lg-none" style={{color:'black'}} ><strong>Kamadgiri Printing Press</strong></h1>
          <input class="form-control me-2 d-none d-lg-block" type="search" placeholder="Search" aria-label="Search"/>
          <button class="btn btn-primary d-none d-lg-block" type="submit">Search</button>
      </form>
      <div className="dropdown mx-3 d-none d-lg-block">
      <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
        <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
        {userLoginType &&<strong className='text-dark'>{userLoginType}</strong>}
      </p>
      <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
        <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
      </ul>
    </div>  
    <button class="navbar-toggler d-lg-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarLabel"><strong>Kamadgiri Printing Press</strong></h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <Link class="nav-link" to="/Admin/AdminPanel">
             <GridViewRoundedIcon style={{ marginRight: '8px' }} />Dashboard</Link>
          </li>
          <li class="nav-item dropdown">
            <strong class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <FlagRoundedIcon style={{ marginRight: '8px' }}/>
              Banner
            </strong>
            <ul class="dropdown-menu">
              <li><Link class="dropdown-item" to="/Admin/AdminPanel"><TimelineRoundedIcon style={{ marginRight: '8px' }}/>Upload Banner</Link></li>
              <li><Link class="dropdown-item" to="/Admin/ViewBannerForm"><BubbleChartRoundedIcon style={{ marginRight: '8px' }}/>View Banner</Link></li>
              </ul>
          </li>
          <li class="nav-item dropdown">
            <strong class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <ImageRoundedIcon style={{ marginRight: '8px' }}/>
              Gallery
            </strong>
            <ul class="dropdown-menu">
              <li><Link class="dropdown-item" to="/Admin/ImgGalryUpldFrm"><PhotoRoundedIcon style={{ marginRight: '8px' }}/>Upload Images</Link></li>
              <li><Link class="dropdown-item" to="/Admin/ViewImgGalryFrm"><PhotoRoundedIcon style={{ marginRight: '8px' }}/>View Images</Link></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <strong class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             <MessageRoundedIcon style={{ marginRight: '8px' }}/>
              Client
            </strong>
            <ul class="dropdown-menu">
              <li><Link class="dropdown-item" to="/Admin/ClientDetails"><MessageRoundedIcon style={{ marginRight: '8px' }}/>View Client Details</Link></li>
            </ul>
          </li>
        </ul>
        <form class="d-flex mt-3" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form>
          <div>
          <div className="dropdown nav-item mt-3" style={{}}>
           <p className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle mb-0" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
             <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
             {userLoginType &&<strong className='text-dark'>{userLoginType}</strong>}
           </p>
           <ul className="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
             <li><Link className="dropdown-item" onClick={handleLogout} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
             <LogoutRoundedIcon style={{ marginRight: '8px' }} />Log out</Link></li>
           </ul>
          </div> 
          <hr />
          </div>
      </div>
    </div>
  </div>
      </nav>
    </>
  )
}
