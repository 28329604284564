import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import FlagRoundedIcon from "@mui/icons-material/FlagRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import BubbleChartRoundedIcon from "@mui/icons-material/BubbleChartRounded";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";


export default function AdminSidebar() {
  const [collapsed, setCollapsed] = useState(false);
  
  const collapseSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
<div className='bg-white'>
<div className='d-none d-lg-flex' style={{ display: "flex", minHeight: "100vh"}}>
        <Sidebar className="app" collapsed={collapsed}>
          <Menu>
            <MenuItem className="menu1 sidebar-item" icon={<MenuRoundedIcon className='icons' onClick={collapseSidebar} />}>
            <div className="text-center mt-3">
              <h6 className="text"><strong>Kamadgiri Printing <br/><span className="d-inline-block">Press</span></strong></h6>
            </div>
            </MenuItem>
            <MenuItem className="sidebar-item" component={<Link to="/Admin/AdminPanel" className="link" />} icon={<GridViewRoundedIcon className="icons" />}> <span className="text">Dashboard</span> </MenuItem>
            <SubMenu className="sidebar-item"  label={<span className="text">Banner</span>} icon={<FlagRoundedIcon className="icons"  />}>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/AdminPanel" className="link" />}  icon={<TimelineRoundedIcon className="text-item"  />}> <span className="text-item">Upload Banner</span> </MenuItem>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/ViewBannerForm" className="link" />}  icon={<BubbleChartRoundedIcon className="text-item"  />}><span className="text-item">View Banner</span></MenuItem>
            </SubMenu>
            <SubMenu className="sidebar-item"  label={<span className="text">Gallery</span>} icon={<ImageRoundedIcon className="icons"  />}>
              <MenuItem className="text-item" component={<Link to="/Admin/ImgGalryUpldFrm" className="link" />}  icon={<PhotoRoundedIcon className="text-item"  />}>
              <span className="text-item">Upload Images</span>
              </MenuItem>
              <MenuItem className="sidebar-item" component={<Link to="/Admin/ViewImgGalryFrm" className="link" />}  icon={<PhotoRoundedIcon className="text-item"  />}><span className="text-item">View Images</span></MenuItem>
            </SubMenu>
            <MenuItem className="text-item" component={<Link to="/Admin/ClientDetails" className="link" />}  icon={<MessageRoundedIcon className="text-item"  />}> <span className="text-item">Client Details</span> </MenuItem>
          </Menu>
        </Sidebar>
    </div>   
</div>
    </>
  )
}
