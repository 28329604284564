import React,{useState, useEffect} from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Sports() {
  const [images, setImages] = useState([]);

  useEffect(() => {
      fetch(`/api/SportSlider`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className='mx-3 mb-5' id='Sport' style={{marginTop:'2rem'}}>
      <div className="text-center mb-4">
        <p className="sub-title">Discover our collection of sports materials</p>
        <h2 className="home-title">Sports Material</h2>
      </div>
        <Carousel
          responsive={responsive}
          ssr
          infinite
          autoPlay
          autoPlaySpeed={3000}
          keyBoardControl
          customTransition="all .5"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {images.map((item, index) => (
            <div key={index}>
              <img src={`/api/Uploads/${item.URL}?timestamp=${new Date().getTime()}`} className='responsive' width='600' height='400' alt={`Product ${index}`} />
              <p className="image-subtitle">{item.Title}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}
