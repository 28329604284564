import React,{useState, useEffect} from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export default function Gallery() {
  const [images, setImages] = useState([]);

  useEffect(() => {
      fetch(`/api/GallerySlider`)
      .then(response => response.json())
      .then(data => {
          const dataArray = data.recordset
          if(Array.isArray(dataArray)){
            setImages(dataArray)
          }else {
              console.error('Invalid data format from the API');
            }
          })
          .catch(error => {
            
          });
  }, []); 
  const responsive = {
    Bbdesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6,
    },
    Bdesktop: {
      breakpoint: { max: 2000, min: 1500 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    Stablet: {
      breakpoint: { max: 768, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <div className='mx-5 my-3' id='Gallery'>
      <div className="text-center mb-4">
        <p className="sub-title">Image Gallery</p>
        <h2 className="home-title">Gallery</h2>
      </div>
      <Carousel
        responsive={responsive}
        ssr
        infinite
        autoPlay
        autoPlaySpeed={5000}
        keyBoardControl
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-10-px shadow-sm p-1 bg-white rounded mx-auto"
      >
        {images.map((item, index) => (
          <div key={index}>
            <img src={`/api/Uploads/${item.URL}?timestamp=${new Date().getTime()}`} className='responsive' width='300' height='200' alt={`Product ${index}`}  />
            {/* <p className="image-subtitle">{item.Title}</p> */}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

