import React, { useState, useEffect } from 'react';

export default function ViewBannerForm() {
  const [image, setImage] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    fetch(`/api/ViewBannerForm`)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
        .catch(error => {
          
        });
  }, []); 

  const handleStatusChange = (clickedImage) => {
    console.log('Image ID:', clickedImage.ID);
    console.log('Image Data:', image);
    const newStatus = clickedImage.Status === 0 ? 1 : 0; // Toggle the status value
    const updatedImages = image.map(img =>
      img.ID === clickedImage.ID ? { ...img, Status: newStatus } : img
    );
    setImage(updatedImages);

    // Make a network request to update the status in the database
    fetch(`/api/UScarousel/${clickedImage.ID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID: clickedImage.ID, newStatus })
    })
      .then(response => response.json())
      .then(data => console.log('Status updated:', data))
      .catch(error => console.error('Error updating status:', error));
  };

  const toggleSelectImage = (clickedImage) => {
    if (selectedImages.some(image => image.ID === clickedImage.ID)) {
      setSelectedImages(selectedImages.filter(image => image.ID !== clickedImage.ID));
    } else {
      setSelectedImages([...selectedImages, clickedImage]);
    }
  };

  const handleDeleteBanner = () => {
    if (selectedImages.length === 0) {
      alert('Select at least one image to delete.');
      return;
    }

    // Send a DELETE request to delete selected images from the database and image files
    const selectedImageIds = selectedImages.map(img => img.ID);

    fetch(`/api/deleteSelectedBanners`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: selectedImageIds })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Images deleted:', data);
       // Remove deleted images from the local state
       const updatedImages = image.filter(img => !selectedImageIds.includes(img.ID));
       setImage(updatedImages);

       // Clear the selected images
       setSelectedImages([]);
     })
     .catch(error => console.error('Error deleting images:', error));
  };

  return (
    <>
      <div className='mx-3 my-5' >
      <h1 className='text-center'>View Banner</h1>
      <div className='shadow mb-5 rounded scrollOnXaxis'>
      <table className='table table-nowrap mb-0 mt-3'>
        <thead className='table-primary'>
          <tr>
            <th>Select</th>
            <th>Banner</th>
            <th>Title</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {image.map(image => (
            <tr key={image.ID}>
              <td>
              <input type="checkbox" checked={selectedImages.some(selImage => selImage.ID === image.ID)} onChange={() => toggleSelectImage(image)} />
            </td>
              <td><img src={`/api/Uploads/${image.URL}`} alt="Banner" className="banner-image" /></td>
              <td>{image.Title}</td>
              <td>{image.Description}</td>
              <td>
              <div className="form-check form-switch d-flex">
              <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked-${image.ID}`}
            checked={image.Status === 0 ? true : false} 
            onChange={() => handleStatusChange(image)} // Call the event handler
          />
          <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${image.ID}`}>
            {image.Status === 0 ? 'Active' : 'Inactive'}
          </label>
          </div>
          </td>
          </tr>
          ))}
        </tbody>
      </table>
      <form className='my-2'>
          <button type='button' className='btn btn-danger' onClick={handleDeleteBanner}  disabled={selectedImages.length === 0}>
            Delete Selected Banners
          </button>
      </form>
      </div>
      </div>
    </>
  );
}