import React, { useState, useEffect } from 'react';

export default function ViewImgGalryFrm() {
  const [image, setImage] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [category, setCategory] = useState('');
  const categaries = ['Marketing', 'Gifting', 'Gallery', 'Stationary', 'Sport']
  const [selectedImages, setSelectedImages] = useState([]);


  const handlefromDateChange = (e) => {
    setFromDate(e.target.value);
  }
  const handletoDateChange = (e) => {
    setToDate(e.target.value);
  }
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevents the form from submitting
    const apiUrl = `/api/ViewImgGalryFrm?fromDate=${fromDate}&toDate=${toDate}&category=${category}`;
    console.log('API URL:', apiUrl);
    fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  };    

  useEffect(() => {
    fetch(`/api/ViewImgGalryFrm`)
    .then(response => response.json())
    .then(data => {
        const dataArray = data.recordset
        if(Array.isArray(dataArray)){
          setImage(dataArray)
        }else {
            console.error('Invalid data format from the API');
          }
        })
        .catch(error => {
          
        });
  }, []); 

  const handleStatusChange = (clickedImage) => {
    console.log('Image ID:', clickedImage.ID);
    const newStatus = clickedImage.Status === 0 ? 1: 0; // Toggle the status value
    const updatedImages = image.map(img =>
      img.ID === clickedImage.ID ? { ...img, Status: newStatus } : img
    );
    setImage(updatedImages);

    // Make a network request to update the status in the database
    fetch(`/api/USimggallery/${clickedImage.ID}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ID: clickedImage.ID, newStatus })
    })
      .then(response => response.json())
      .then(data => console.log('Status updated:', data))
      .catch(error => console.error('Error updating status:', error));
  };
  
  const toggleSelectImage = (clickedImage) => {
    if (selectedImages.some(image => image.ID === clickedImage.ID)) {
      setSelectedImages(selectedImages.filter(image => image.ID !== clickedImage.ID));
    } else {
      setSelectedImages([...selectedImages, clickedImage]);
    }
  };

  const handleDeleteBanner = () => {
    if (selectedImages.length === 0) {
      alert('Select at least one image to delete.');
      return;
    }

    const selectedImageIds = selectedImages.map(img => img.ID);

    fetch(`/api/deleteSelectedGalleryImg`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ids: selectedImageIds })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Images deleted:', data);
       // Remove deleted images from the local state
       const updatedImages = image.filter(img => !selectedImageIds.includes(img.ID));
       setImage(updatedImages);

       // Clear the selected images
       setSelectedImages([]);
     })
     .catch(error => console.error('Error deleting images:', error));
  };

  return (
    <>
      <div className='mx-3 my-5' >
     <div className='shadow-sm p-3 mb-3 bg-white rounded mb-5'>
     <h3 className='text-center'>Image Gallery</h3>
      <form className='row  mx-0 d-flex justify-content-around' onSubmit={handleSubmit}>
        <div className='col-md-1'>
          <label  className='form-label' htmlFor="title">From Date:</label>
        </div>
        <div className='col-md-2 mb-2'>
          <input  className='form-input' type="Date" id="fromDate" name='fromDate' value={fromDate}  onChange={handlefromDateChange} required />
        </div>
        <div className='col-md-1'>
          <label  className='form-label' htmlFor="image">To Date:</label>
        </div>
        <div className='col-md-2 mb-2'>
          <input  className='form-input' type="Date"  name="toDate" id="toDate"  value={toDate} onChange={handletoDateChange} required />
        </div>
        <div className='col-md-1'>
          <label  className='form-label' htmlFor="title">Category:</label>
        </div>
        <div className='col-md-2 mb-2'>
          <select className="form-control" id="dropdown" name="gender" value={category} onChange={handleCategoryChange} required>
              <option value="">Select an option</option>
              {categaries.map((option, index) => (
                  <option key={index} value={option}>
                      {option}
                  </option>
              ))}
          </select>
        </div>
        <div className='col-md-2'>
        <button className='form-submit' type="submit">Apply Filter</button>
        </div>
      </form>
     </div>
    
      <div className='shadow mb-5 rounded'>
      <table className='table table-nowrap mb-0 mt-3'>
        <thead className='table-primary'>
          <tr>
            <th>Select</th>
            <th>Date</th>
            <th>Images</th>
            <th>Category</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {image.map(image => (
            <tr key={image.ID}>
              <td className='status-column '>
                <input type="checkbox" checked={selectedImages.some(selImage => selImage.ID === image.ID)} onChange={() => toggleSelectImage(image)} />
              </td>
              <td>{image.Date.slice(0, 10)}</td>
              <td><img src={`/api/Uploads/${image.URL}`} alt="Banner" className="banner-image " /></td>
              <td>{image.Category}</td>
              <td>
              <div className="form-check form-switch">
              <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            id={`flexSwitchCheckChecked-${image.ID}`}
            checked={image.Status === 0 ? true : false} 
            onChange={() => handleStatusChange(image)} // Call the event handler
          />
          <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${image.ID}`}>
            {image.Status === 0 ? 'Active' : 'Inactive'}
          </label>
          </div>
          </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <form method="GET" className='form1 my-4'>
        <button type="submit" className='btn btn-danger' onClick={handleDeleteBanner}  disabled={selectedImages.length === 0}>Delete Selected Image</button>
      </form>
      </div>
    </>
  );
}