import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import flexbanner from '../Images/flexbanner.jpg';
import lettter from '../Images/offsetPrint.jpg';
import printingServices from '../Images/printingServices.jpeg';
import bindingSpiral from '../Images/bindingSpiral.jpg';
import letterLight from '../Images/letterLight.jpg';
import weddingCard from '../Images/wedding-card.jpg';
import idCard from '../Images/idCard.jpeg';
import stationery from '../Images/offset-printing-press-services.jpg';

export default function Services() {
  const responsive = {
    Bbdesktop: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6,
    },
    Bdesktop: {
      breakpoint: { max: 2000, min: 1500 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    Stablet: {
      breakpoint: { max: 768, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const images = [
    { src: flexbanner, subtitle: 'Flex/Banner', description: 'Experience the epitome of flexibility with our banner services.' },
    { src: lettter, subtitle: 'Offset Printing', description: 'Discover precision and vividness in every print with our offset printing expertise.' },
    { src: printingServices, subtitle: 'Printing Services', description: 'Unlock boundless possibilities with our comprehensive printing services.' },
    { src: bindingSpiral, subtitle: 'Binding/Spiral', description: 'Elevate your presentations and documents with our binding and spiral services.' },
    { src: letterLight, subtitle: 'Letter Light Board', description: 'Illuminate your message with our letter light board. Craft impactful displays or messages that shine brightly.' },
    { src: weddingCard, subtitle: 'Wedding Card', description: 'Elevate your special day with exquisite wedding cards from our printing press.' },
    { src: idCard, subtitle: 'ID Card', description: 'Empower identification with our meticulously crafted ID cards. Whether for corporations, institutions, or events.' },
    { src: stationery, subtitle: 'Stationary', description: 'Define your professional image with our curated selection of stationery.' },
    { src: lettter, subtitle: 'bill/bound book', description: 'Effortlessly manage your financial records with our professional bill/bound books.' },
  ];

  return (
    <div className='mx-5 my-3 mobileVisiblity' id='Services'>
      <div className="text-center mb-4">
        <p className="sub-title">Media Consultancy</p>
        <h2 className="home-title">Services</h2>
      </div>
      <Carousel
        responsive={responsive}
        ssr
        infinite
        keyBoardControl
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px shadow-sm p-3 bg-white rounded mx-auto"
      >
        {images.map((item, index) => (
          <div key={index}>
            <img src={item.src} alt={`Product ${index}`} className='responsive' width='300' height='200' />
            <p className="image-subtitle fw-bold">{item.subtitle}</p>
            <p className="short-description">{item.description}</p>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
